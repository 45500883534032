import { OverlaysState } from "../overlays.context";

export interface CloseToast {
    type: 'CLOSE_TOAST';
}

const closeToast = (
    state: OverlaysState
): OverlaysState => {
    return {
        ...state,
        currentToast: null,
    }
}

export default closeToast