import { OverlaysState } from "../overlays.context";

export interface CloseConfirm {
    type: 'CLOSE_CONFIRM';
}

const closeConfirm = (
    state: OverlaysState
): OverlaysState => {
    return {
        ...state,
        currentConfirm: null,
    }
}

export default closeConfirm