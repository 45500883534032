import { useState, useEffect, useContext } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { AuthStore } from 'context/auth/auth.context';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URI;

type Execution = 'auto' | 'manual'

type Config<T> = {
    execution?: Execution
    withAuth?: boolean
    callback?: (t: T) => unknown
}

const defaultConfig: Config<unknown> = {
    execution: 'auto',
    withAuth: true,
}

type AxiosReturn<T> = {
    response?: T
    error: AxiosError
    loading: boolean
    sendRequest: (paramsOverrides?: AxiosRequestConfig) => Promise<T | undefined>;
    status?: number
}

export const useAxios = <T>(axiosParams: AxiosRequestConfig, config?: Config<T>): AxiosReturn<T> => {
    const [response, setResponse] = useState<{data: T, status: number }>();
    const [error, setError] = useState<AxiosError<T>>({} as AxiosError);
    const [loading, setLoading] = useState(false);

    const { state } = useContext(AuthStore)

    const requestConfig = {
        ...defaultConfig,
        ...config
    }

    const sendRequest = async (paramsOverrides = {}) => {
        try {
            setLoading(true);
            setError({} as AxiosError);
            const result = await axios.request({
                ...axiosParams,
                headers: {
                    ...axiosParams.headers,
                    ...( requestConfig.withAuth ? { authorization: `Bearer ${state.token}` } : {}),
                },
                ...paramsOverrides,
            });
            setResponse({data: result.data, status: result.status});
            return result.data
        } catch(error: unknown) {
            const axiosError = error as AxiosError
            setError(axiosError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (requestConfig.execution === 'auto') {
            sendRequest();
        }
    }, [requestConfig.execution]);

    useEffect(() => {
        if (requestConfig.callback && response) {
            requestConfig.callback(response.data)
        }
    }, [response]);

    return { response: response?.data, error, loading, sendRequest, status: response?.status };
};