import { Context, createContext, ReactNode, useReducer } from "react"
import reducer, { NotificationsAction } from "./notifications.reducer";
import { Notifications } from 'model/notification'

export type NotificationsState = {
    notifications: Notifications
    lastSeen: Date
    count: number
    unseenCount: number
}

export interface DispatchNotificationsActions {
    (action: NotificationsAction): void
}

export interface NotificationsStateStore {
    state: NotificationsState;
    dispatch: DispatchNotificationsActions;
}

export const initialState: NotificationsState = {
    notifications: {
        friendRequests: [],
        friendRequestsAccepted: [],
        birthdays: [],
        ownBirthdays: [],
        confirmGifts: []
    },
    lastSeen: new Date(0),
    count: 0,
    unseenCount: 0
}

const useCreateStore = (): NotificationsStateStore => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return { state, dispatch }
}

export const NotificationsStore: Context<NotificationsStateStore> = createContext<NotificationsStateStore>({
    state: initialState,
    dispatch: () => {}
})

export const NotificationsProvider = (props: { children: ReactNode }) => {
    const store = useCreateStore()
    return <NotificationsStore.Provider value={store}>{props.children}</NotificationsStore.Provider>
}

export default { NotificationsStore, NotificationsProvider }
