import { ReactNode } from "react";
import { OverlaysState } from "../overlays.context";

export interface OpenConfirm {
    type: 'OPEN_CONFIRM';
    content: ReactNode
}

const openConfirm = (
    state: OverlaysState,
    action: OpenConfirm
): OverlaysState => {
    return {
        ...state,
        currentConfirm: action.content,
    }
}

export default openConfirm