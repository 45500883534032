import { OverlaysStore } from 'context/ui/overlays.context';
import { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import close from 'assets/images/close.svg';

import styles from './confirm.view.module.scss';

const Confirm: React.FC = () => {
    const { state, dispatch } = useContext(OverlaysStore)
    const [playTransition, setPlayTransition] = useState(false)

    useEffect(() => {
        setPlayTransition(!!state.currentConfirm)
    }, [state.currentConfirm])

    const closeToast = () => {
        dispatch({type: 'CLOSE_CONFIRM'})
    }

    return (
        state.currentConfirm ?
            <CSSTransition
                in={playTransition}
                timeout={{enter: 500, exit: 200}}
                classNames={{
                    enter: styles['wrapper-enter'],
                    enterActive: styles['wrapper-enter-active'],
                    enterDone: styles['wrapper-enter-done'],
                    exit: styles['wrapper-exit'],
                    exitActive: styles['wrapper-exit-active'],
                }}
            >
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <img src={close} alt="Cerrar" onClick={closeToast} className={styles.close} />
                        {state.currentConfirm}
                    </div>
                </div>
            </CSSTransition>
            :
            null
    )
}

export default Confirm
