import { Context, createContext, ReactNode, useReducer } from "react"
import reducer, { OverlaysAction } from "./overlays.reducer";

export interface OverlaysState {
    currentModal: ReactNode;
    currentToast: ReactNode;
    currentConfirm: ReactNode;
}

export interface DispatchOverlaysActions {
    (action: OverlaysAction): void
}

export interface OverlaysStateStore {
    state: OverlaysState;
    dispatch: DispatchOverlaysActions;
}

const initialState: OverlaysState = {
    currentModal: null,
    currentToast: null,
    currentConfirm: null,
}

const useCreateStore = (): OverlaysStateStore => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return { state, dispatch }
}

export const OverlaysStore: Context<OverlaysStateStore> = createContext<OverlaysStateStore>({
    state: initialState,
    dispatch: () => {}
})

export const OverlaysProvider = (props: { children: ReactNode }) => {
    const store = useCreateStore()
    return <OverlaysStore.Provider value={store}>{props.children}</OverlaysStore.Provider>
}

export default { OverlaysStore, OverlaysProvider }
