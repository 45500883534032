import setRedirectTo, { RedirectToInitialize } from "./actions/redirect-to-initialize.action";
import setToken, { TokenInitialize } from "./actions/token-initialize.action";
import removeToken, { TokenRemove } from "./actions/token-remove.action";
import { AuthState } from "./auth.context";

export type AuthAction = TokenInitialize | TokenRemove | RedirectToInitialize

const reducer = (
    state: AuthState,
    action: AuthAction
): AuthState => {
    switch(action.type) {
        case 'TOKEN_INITIALIZE':
            return setToken(state, action)
        case 'TOKEN_REMOVE':
            return removeToken(state)
        case 'REDIRECT_TO_INITIALIZE':
            return setRedirectTo(state, action)
        default:
            return state
    }
}

export default reducer;