import setUser, { UserInitialize } from "./actions/user-initialize.action";
import resetUser, { UserFinalize } from "./actions/user-finalize.action";
import setUserProfileFetched, { UserprofileFetched } from "./actions/user-profile-fetched.action";
import setUserProfileUpdated, { UserprofileUpdated } from "./actions/user-profile-updated.action";
import { UserState } from "./user.context";

export type UserAction = UserInitialize | UserFinalize | UserprofileFetched | UserprofileUpdated

const reducer = (
    state: UserState,
    action: UserAction
): UserState => {
    switch(action.type) {
        case 'USER_INITIALIZE':
            return setUser(state, action)
        case 'USER_FINALIZE':
            return resetUser(state)
        case 'USER_PROFILE_FETCHED':
            return setUserProfileFetched(state, action)
        case 'USER_PROFILE_UPDATED':
            return setUserProfileUpdated(state, action)
        default:
            return state
    }
}

export default reducer;