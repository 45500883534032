import { NotificationsState } from "../notifications.context";
import { Notifications } from 'model/notification'

export interface SetNotifications {
    type: 'SET_NOTIFICATIONS'
    notifications: Partial<Notifications>
    lastSeen?: Date
    count?: number
    unseenCount?: number
}

const setNotifications = (
    state: NotificationsState,
    action: SetNotifications
): NotificationsState => {
    const { type, notifications, ...rest } = action
    return {
        ...state,
        notifications: {
            ...state.notifications,
            ...notifications,
        },
        ...rest
    }
}

export default setNotifications