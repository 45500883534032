import { useState } from 'react';
import PropTypes from 'prop-types'

import styles from './drag-to-action.module.scss';

const MIN_DRAG_DISTANCE = 10
const DEFAULT_DRAG_Y = Number.MAX_SAFE_INTEGER

type DragToActionProps = {
    action: () => void,
}
type DragToActionComponent = React.FC<DragToActionProps>

const DragToAction: DragToActionComponent = ({action}) => {

    const [dragY, setDragY] = useState(DEFAULT_DRAG_Y)

    const mouseDown = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setDragY(ev.clientY)
    }
    const mouseMove = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (ev.clientY - dragY > MIN_DRAG_DISTANCE) {
            action()
            setDragY(DEFAULT_DRAG_Y)
        }
    }

    const touchStart: React.TouchEventHandler<HTMLDivElement> = ev => {
        if (ev.touches.length === 1) {
            setDragY(ev.touches[0].clientY)
        }
    }
    const touchMove: React.TouchEventHandler<HTMLDivElement> = ev => {
        if (ev.touches.length === 1 && ev.touches[0].clientY - dragY > MIN_DRAG_DISTANCE) {
            action()
            setDragY(DEFAULT_DRAG_Y)
        }
    }

    const dragEnd = () => {
        setDragY(DEFAULT_DRAG_Y)
    }

    return (
        <div
            className={styles.drag_zone}
            onMouseDown={mouseDown}
            onMouseMove={mouseMove}
            onMouseUp={dragEnd}
            onTouchStart={touchStart}
            onTouchMove={touchMove}
            onTouchEnd={dragEnd}
        >
            <span className={styles.drag_icon} />
        </div>
    )
}

DragToAction.propTypes = {
    action: PropTypes.func.isRequired
}

export default DragToAction