import openModal, { OpenModal } from "./actions/open-modal.action";
import closeModal, { CloseModal } from "./actions/close-modal.action";
import openToast, { OpenToast } from "./actions/open-toast.action";
import closeToast, { CloseToast } from "./actions/close-toast.action";
import openConfirm, { OpenConfirm } from "./actions/open-confirm.action";
import closeConfirm, { CloseConfirm } from "./actions/close-confirm.action";
import { OverlaysState } from "./overlays.context";

export type OverlaysAction = OpenModal | CloseModal | OpenToast | CloseToast | OpenConfirm | CloseConfirm

const reducer = (
    state: OverlaysState,
    action: OverlaysAction
): OverlaysState => {
    switch(action.type) {
        case 'OPEN_MODAL':
            return openModal(state, action)
        case 'CLOSE_MODAL':
            return closeModal(state)
        case 'OPEN_TOAST':
            return openToast(state, action)
        case 'CLOSE_TOAST':
            return closeToast(state)
        case 'OPEN_CONFIRM':
            return openConfirm(state, action)
        case 'CLOSE_CONFIRM':
            return closeConfirm(state)
        default:
            return state
    }
}

export default reducer;