import profilePlaceholder from 'assets/images/bottomNavigation/profile.svg';
import styles from './profile-picture.view.module.scss';

type ProfilePictureProps = {
    picture?: string
    size?: number,
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({picture, size = 3.375}: ProfilePictureProps) => (
    <div style={{height: `${size}rem`, width: `${size}rem`}}>
        { picture ?
            <img className={styles.profile} src={picture} />
            :
            <img className={styles.placeholder} src={profilePlaceholder} alt="Profile" />
        }
    </div>
)

export default ProfilePicture