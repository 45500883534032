import { OverlaysStore } from 'context/ui/overlays.context';
import { useContext, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import DragToAction from 'components/drag-to-action/drag-to-action';
import { Capacitor } from '@capacitor/core';
import close from 'assets/images/close.svg';

import styles from './modal.view.module.scss';

const Modal: React.FC = () => {
    const { state, dispatch } = useContext(OverlaysStore)
    const [playTransition, setPlayTransition] = useState(false)

    useEffect(() => {
        setPlayTransition(!!state.currentModal)
    }, [state.currentModal])

    const closeModal = () => {
        setPlayTransition(false)
        setTimeout(() => {
            dispatch({type: 'CLOSE_MODAL'})
        }, 200)
    }

    return (
        state.currentModal ?
            <CSSTransition
                in={playTransition}
                timeout={{enter: 500, exit: 200}}
                classNames={{
                    enter: styles['wrapper-enter'],
                    enterActive: styles['wrapper-enter-active'],
                    enterDone: styles['wrapper-enter-done'],
                    exit: styles['wrapper-exit'],
                    exitActive: styles['wrapper-exit-active'],
                }}
            >
                <div className={styles.wrapper}>
                    <div
                        className={styles.veil}
                        onMouseDown={closeModal}
                        onTouchStart={closeModal}
                    />
                    { Capacitor.isNativePlatform() ?
                        <CSSTransition
                            in={playTransition}
                            timeout={200}
                            classNames={{
                                enter: styles['content-enter'],
                                enterActive: styles['content-enter-active'],
                                exit: styles['content-exit'],
                                exitActive: styles['content-exit-active'],
                            }}
                            unmountOnExit
                        >
                            <div className={styles.content}>
                                <DragToAction action={closeModal} />
                                {state.currentModal}
                            </div>
                        </CSSTransition>
                        :
                        <div className={styles.web_content}>
                            <img src={close} alt="Cerrar" onClick={closeModal} className={styles.close} />
                            {state.currentModal}
                        </div>
                    }
                </div>
            </CSSTransition>
            :
            null
    )
}

export default Modal
