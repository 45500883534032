import setNotifications, { SetNotifications } from "./actions/set-notifications.action";
import { NotificationsState } from "./notifications.context";

export type NotificationsAction = SetNotifications

const reducer = (
    state: NotificationsState,
    action: NotificationsAction
): NotificationsState => {
    switch(action.type) {
        case 'SET_NOTIFICATIONS':
            return setNotifications(state, action)
        default:
            return state
    }
}

export default reducer;