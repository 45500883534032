import ProtectedRoute from 'components/protected-route';
import { AuthProvider } from 'context/auth/auth.context';
import { UserProvider } from 'context/user/user.context';
import { OverlaysProvider } from 'context/ui/overlays.context';
import { NotificationsProvider } from 'context/notifications/notifications.context';
import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Modals from 'components/modals/modal.view'
import Toasts from 'components/toasts/toast.view'
import Confirms from 'components/confirms/confirm.view'
import Spinner from 'components/spinner/spinner'
import BottomNavigation from 'components/bottomNavigation.view';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

const Landing = lazy(() => import('./views/landing.view'))
const UploadGift = lazy(() => import('./views/upload/uploadGift.view'))
const Home = lazy(() => import('./views/home.view'))
const RegisterForm = lazy(() => import('./views/register-form.view'))
const CreateForm = lazy(() => import('./views/create-form.view'))
const RegisterProfile = lazy(() => import('./views/register-profile.view'))
const MyLists = lazy(() => import('./views/my-lists/view'))
const List = lazy(() => import('./views/list/view'))
const Friends = lazy(() => import('./views/friends/view'))
const Friend = lazy(() => import('./views/friend/friend.view'))
const FriendRequests = lazy(() => import('./views/friendRequests/view'))
const AddFriends = lazy(() => import('./views/addFriends/view'))
const UploadForm = lazy(() => import('./views/upload/uploadForm.view'))
const NewList = lazy(() => import('./views/new-list/view'))
const Profile = lazy(() => import('./views/profile/view'))
const EditProfile = lazy(() => import('./views/profile/edit.view'))
const FriendLists = lazy(() => import('./views/friend/lists/friend-lists.view'))
const Gift = lazy(() => import('./views/gift/view'))
const Feedback = lazy(() => import('./views/feedback.view'))
const EditList = lazy(() => import('./views/list/edit.view'))
const Notifications = lazy(() => import('./views/notifications/view'))
const FriendFriends = lazy(() => import('./views/friend/friends/friend-friends.view'))
const ForgetPassword = lazy(() => import('./views/forget-password/view'))
const ResetPassword = lazy(() => import('./views/reset-password/view'))
const TermsAndConditions = lazy(() => import('./views/terms-and-conditions/view'))
const Onboarding = lazy(() => import('./views/onboarding/view'))

const App: React.FC = () => {

  if (Capacitor.isNativePlatform()) {
    GoogleAuth.initialize()
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <AuthProvider>
        <OverlaysProvider>
        <UserProvider>
          <NotificationsProvider>
              <Router>
                <Suspense fallback={<Spinner />}>
                  <Switch>
                    <ProtectedRoute exact path='/' component={Landing} />
                    <Route exact path='/welcome' component={Landing} />
                    <ProtectedRoute exact path='/upload' component={UploadGift} />
                    <ProtectedRoute exact path='/home' component={Home} />
                    <Route exact path='/register' component={RegisterForm} />
                    <Route exact path='/create' component={CreateForm} />
                    <ProtectedRoute exact path='/register-profile' component={RegisterProfile} />
                    <ProtectedRoute exact path='/lists' component={MyLists} />
                    <Route exact path='/list/:id' component={List} />
                    <ProtectedRoute exact path='/friends' component={Friends} />
                    <ProtectedRoute exact path='/friend/:id' component={Friend} />
                    <ProtectedRoute exact path='/friend-requests' component={FriendRequests} />
                    <ProtectedRoute exact path='/add-friends' component={AddFriends} />
                    <ProtectedRoute exact path='/upload-form' component={UploadForm} />
                    <ProtectedRoute exact path='/new-list' component={NewList} />
                    <ProtectedRoute exact path='/profile' component={Profile} />
                    <ProtectedRoute exact path='/edit-profile' component={EditProfile} />
                    <ProtectedRoute exact path='/friend-lists' component={FriendLists} />
                    <Route exact path='/gift/:id' component={Gift} />
                    <ProtectedRoute exact path='/feedback' component={Feedback} />
                    <ProtectedRoute exact path='/list/:id/edit' component={EditList} />
                    <ProtectedRoute exact path='/notifications' component={Notifications} />
                    <ProtectedRoute exact path='/friend-friends' component={FriendFriends} />
                    <Route exact path='/forget-password' component={ForgetPassword} />
                    <Route exact path='/reset-password/:token' component={ResetPassword} />
                    <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
                    <Route exact path='/onboarding' component={Onboarding} />
                  </Switch>
                  <BottomNavigation />
                  <Modals />
                  <Toasts />
                  <Confirms />
                </Suspense>
              </Router>
            </NotificationsProvider>
          </UserProvider>
        </OverlaysProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
