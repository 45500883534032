import { initialState, UserState } from "../user.context";

export interface UserFinalize {
    type: 'USER_FINALIZE';
}

const resetUser = (
    state: UserState,
): UserState => {
    return {
        ...state,
        profile: initialState.profile
    }
}

export default resetUser