import { UserState } from "../user.context";

interface User {
    firstName: string;
    lastName: string;
    birthday: Date;
    picture?: string;
}

export interface UserInitialize {
    type: 'USER_INITIALIZE';
    user: User
}

const setUser = (
    state: UserState,
    action: UserInitialize
): UserState => {
    return {
        ...state,
        profile: {
            ...state.profile,
            firstName: action.user.firstName,
            lastName: action.user.lastName,
            birthday: action.user.birthday,
            picture: action.user.picture
        }
    }
}

export default setUser