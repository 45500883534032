import { AuthState } from "../auth.context";

export interface RedirectToInitialize {
    type: 'REDIRECT_TO_INITIALIZE'
    path: string
    state?: { [key: string]: unknown }
}

const setRedirectTo = (
    state: AuthState,
    action: RedirectToInitialize
): AuthState => {
    return {
        ...state,
        redirectTo: action.path,
        state: action.state
    }
}

export default setRedirectTo