import { OverlaysStore } from 'context/ui/overlays.context';
import { useContext, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './toast.view.module.scss';

const Toast: React.FC = () => {
    const { state, dispatch } = useContext(OverlaysStore)
    const [playTransition, setPlayTransition] = useState(false)

    useEffect(() => {
        setPlayTransition(!!state.currentToast)
        const closeTimeout = setTimeout(() => {
            closeToast()
        }, 3000)

        return () => {
            clearTimeout(closeTimeout)
        }
    }, [state.currentToast])

    const closeToast = () => {
        setPlayTransition(false)
        setTimeout(() => {
            dispatch({type: 'CLOSE_TOAST'})
        }, 200)
    }

    return (
        state.currentToast ?
            <div className={styles.wrapper}>
                <CSSTransition
                    in={playTransition}
                    timeout={200}
                    classNames={{
                        enter: styles['content-enter'],
                        enterActive: styles['content-enter-active'],
                        exit: styles['content-exit'],
                        exitActive: styles['content-exit-active'],
                    }}
                    unmountOnExit
                >
                    <div className={styles.content}>
                        <div className={styles.scrollable_content}>
                            {state.currentToast}
                        </div>
                    </div>
                </CSSTransition>
            </div>
            :
            null
    )
}

export default Toast
