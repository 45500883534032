import styles from './notification-dot.module.scss';

interface NotificationDotProps {
    count: number
}

const displayCount = (count: number) => count > 9 ? '+9' : count

const NotificationDot: React.FC<NotificationDotProps> = ({count}: NotificationDotProps) => (
    !!count ? <span className={styles.wrapper}>{displayCount(count)}</span> : null
)

export default NotificationDot
