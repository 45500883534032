import { AuthState } from "../auth.context";

export interface TokenRemove {
    type: 'TOKEN_REMOVE'
}

const removeToken = (
    state: AuthState
): AuthState => {
    return {
        ...state,
        token: '',
        redirectTo: ''
    }
}

export default removeToken