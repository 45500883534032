import { AuthStore } from "context/auth/auth.context"
import { FunctionComponent, useContext, useEffect, useState } from "react"
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom"
import { Preferences } from '@capacitor/preferences'; 
import Spinner from "./spinner/spinner";

const ProtectedRoute: FunctionComponent<RouteProps> = (props) => {
    const [loadingStorage, setLoadingStorage] = useState(true);
    const { state, dispatch } = useContext(AuthStore)
    const location = useLocation();

    useEffect(() => {
        if (!state.token) {
            (async () => {
                const { value } = await Preferences.get({key: 'token'})
                dispatch({type: 'TOKEN_INITIALIZE', value: value || ''})
                setLoadingStorage(false)
            })()
        }
    }, [state.token])


    if (state.token) {
        if (props.path === '/') {
            return <Redirect to='/home' />
        } else {
            return <Route {...props} component={props.component} />
        }
    } else if (loadingStorage) {
        return <Spinner />
    } else {
        const locationValue = location.pathname === '/' ? '' : `${location.pathname}${location.search}`
        dispatch({type: 'REDIRECT_TO_INITIALIZE', path: locationValue})
        return <Redirect to='/welcome' />
    }
   
}

export default ProtectedRoute