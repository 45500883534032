import { ReactNode } from "react";
import { OverlaysState } from "../overlays.context";

export interface OpenToast {
    type: 'OPEN_TOAST';
    content: ReactNode
}

const openToast = (
    state: OverlaysState,
    action: OpenToast
): OverlaysState => {
    return {
        ...state,
        currentToast: action.content,
    }
}

export default openToast