import { AuthState } from "../auth.context";

export interface TokenInitialize {
    type: 'TOKEN_INITIALIZE'
    value: string
}

const setToken = (
    state: AuthState,
    action: TokenInitialize
): AuthState => {
    return {
        ...state,
        token: action.value
    }
}

export default setToken