import add from '../assets/images/bottomNavigation/add.svg';
import friends from '../assets/images/bottomNavigation/friends.svg';
import lists from '../assets/images/bottomNavigation/lists.svg';
import notificationsIcon from '../assets/images/bottomNavigation/notifications.svg';
import friendsBlue from '../assets/images/bottomNavigation/friends_blue.svg';
import listsBlue from '../assets/images/bottomNavigation/lists_blue.svg';
import notificationsIconBlue from '../assets/images/bottomNavigation/notifications_blue.svg';

import styles from './bottomNavigation.view.module.scss';

import { useHistory, useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { UserStore } from 'context/user/user.context';
import ProfilePicture from './profile-picture/profile-picture.view';
import { useAxios } from 'utilities/useAxios';
import NotificationDot from './notifications/notification-dot';
import { AuthStore } from 'context/auth/auth.context';
import { NotificationsStore } from 'context/notifications/notifications.context';
import { User } from 'model/user';
import { NotificationsResponse } from 'views/notifications/view';

const fullScreenViews = [
    '/welcome',
    '/upload',
    '/register',
    '/create',
    '/register-profile',
    '/friend/',
    '/friend-requests',
    '/add-friends',
    '/upload-form',
    '/new-list',
    '/profile',
    '/edit-profile',
    '/gift/',
    '/feedback',
    '/onboarding',
]

const BottomNavigation: React.FC = () => {

    const { state: authState } = useContext(AuthStore)
    const { state: { notifications, unseenCount }, dispatch: notificationsDispatch } = useContext(NotificationsStore);
    const { dispatch: userDispatch } = useContext(UserStore)

    const location = useLocation();

    const { sendRequest: getNotifications } = useAxios<NotificationsResponse>(
        {
            method: 'GET',
            url: '/notifications/all'
        },
        {
            execution: 'manual',
            callback: response => {
                notificationsDispatch({type: 'SET_NOTIFICATIONS', ...response})
            }
        }
    )

    const { sendRequest: getUserData } = useAxios<User>(
        {
            method: 'GET',
            url: '/users/me',
        },
        {
            execution: 'manual',
            callback: ({firstName, lastName, birthday, picture}: User) => {
                userDispatch({type: 'USER_INITIALIZE', user: {firstName: firstName, lastName: lastName, birthday: new Date(birthday), picture}})
            }
        }
    )
    const isFullScreenView = !!fullScreenViews.find(view => location.pathname.includes(view))

    useEffect(() => {
        if (authState.token && !isFullScreenView) {
            getNotifications()
            getUserData()
        }
    }, [authState.token])

    const { state: {profile}} = useContext(UserStore);

    const history = useHistory();
    const handleNotificationsClick = () => history.push('/notifications');
    const handleAddClick = () => history.push('/upload');
    const handleFriendsClick = () => history.push('/friends')
    const handleListsClick = () => history.push('/lists')
    const handleProfileClick = () => history.push('/profile');

    const isListsView = location.pathname === '/lists' || location.pathname.includes('/list/')

    return isFullScreenView || !authState.token ? null : (
        <div className={styles.toolbar}>
            <a onClick={handleNotificationsClick} className={styles.button}>
                <img src={location.pathname === '/notifications' ? notificationsIconBlue : notificationsIcon} alt="Notifications" />
                <NotificationDot count={unseenCount} />
                {location.pathname === '/notifications' && <span className={styles.selectionDot} />}
            </a>
            <a onClick={handleFriendsClick} className={styles.button}>
                <img src={location.pathname === '/friends' ? friendsBlue : friends} alt="Friends" />
                <NotificationDot count={notifications.friendRequests.length} />
                {location.pathname === '/friends' && <span className={styles.selectionDot} />}
            </a>
            <a onClick={handleAddClick} className={styles.mainButton}>
                <img src={add} alt="Add" />
            </a>
            <a onClick={handleListsClick} className={styles.button}>
                <img src={isListsView ? listsBlue : lists} alt="Lists" />
                {isListsView && <span className={styles.selectionDot} />}
            </a>
            <a onClick={handleProfileClick} className={styles.button}>
                <ProfilePicture picture={profile.picture} size={1.625} />
            </a>
        </div>
    )
}

export default BottomNavigation