import { Profile } from "model/profile";
import { UserState } from "../user.context";

export interface UserprofileFetched {
    type: 'USER_PROFILE_FETCHED';
    profile: Profile
}

const setUserProfileFetched = (
    state: UserState,
    action: UserprofileFetched
): UserState => {
    return {
        ...state,
        profile: {
            ...state.profile,
            ...action.profile,
        }
    }
}

export default setUserProfileFetched