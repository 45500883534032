import { ReactNode } from "react";
import { OverlaysState } from "../overlays.context";

export interface OpenModal {
    type: 'OPEN_MODAL';
    content: ReactNode
}

const openModal = (
    state: OverlaysState,
    action: OpenModal
): OverlaysState => {
    return {
        ...state,
        currentModal: action.content,
    }
}

export default openModal