import { Profile } from "model/profile";
import { UserState } from "../user.context";

export interface UserprofileUpdated {
    type: 'USER_PROFILE_UPDATED';
    profile: Partial<Profile>
}

const setUserProfileUpdated = (
    state: UserState,
    action: UserprofileUpdated
): UserState => {
    return {
        ...state,
        profile: {
            ...state.profile,
            ...action.profile,
        }
    }
}

export default setUserProfileUpdated