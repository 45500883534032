import { OverlaysState } from "../overlays.context";

export interface CloseModal {
    type: 'CLOSE_MODAL';
}

const closeModal = (
    state: OverlaysState
): OverlaysState => {
    return {
        ...state,
        currentModal: null,
    }
}

export default closeModal