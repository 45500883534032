import { Profile } from "model/profile";
import { Context, createContext, ReactNode, useReducer } from "react"
import reducer, { UserAction } from "./user.reducer";

export interface UserState {
    profile: Profile
}

export interface DispatchUserActions {
    (action: UserAction): void
}

export interface UserStateStore {
    state: UserState;
    dispatch: DispatchUserActions;
}

export const initialState: UserState = {
    profile: {
        id: '',
        firstName: '',
        lastName: '',
        birthday: null,
        status: 'online',
        listsAmount: 0,
        friendsAmount: 0,
        giftsMade: 0,
        sizes: {},
    }
}

const useCreateStore = (): UserStateStore => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return { state, dispatch }
}

export const UserStore: Context<UserStateStore> = createContext<UserStateStore>({
    state: initialState,
    dispatch: () => {}
})

export const UserProvider = (props: { children: ReactNode }) => {
    const store = useCreateStore()
    return <UserStore.Provider value={store}>{props.children}</UserStore.Provider>
}

export default { UserStore, UserProvider }
