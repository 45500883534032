import { Context, createContext, ReactNode, useReducer } from "react"
import reducer, { AuthAction } from "./auth.reducer"

export interface AuthState {
    token: string;
    redirectTo: string;
    state?: { [key: string]: unknown };
}

export interface DispatchAuthActions {
    (action: AuthAction): void
}

export interface AuthStateStore {
    state: AuthState
    dispatch: DispatchAuthActions
}

const initialState: AuthState = {
    token: '',
    redirectTo: '',
    state: {}
}

const useCreateStore = (): AuthStateStore => {
    const [state, dispatch] = useReducer(reducer, initialState)
    return { state, dispatch }
}

export const AuthStore: Context<AuthStateStore> = createContext<AuthStateStore>({
    state: initialState,
    dispatch: () => {}
})

export const AuthProvider = (props: { children: ReactNode }) => {
    const store = useCreateStore()
    return <AuthStore.Provider value={store}>{props.children}</AuthStore.Provider>
}

export default { AuthStore, AuthProvider }